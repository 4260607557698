import {graphql, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Head from '../components/Head/Head.jsx'
import {Heading1} from '../components/Heading/Heading.jsx'
import Page from '../components/Page/Page.jsx'
import {StyledProductFigcaption, StyledProductFigure, StyledProductsPageDescription, StyledProductsSection} from '../page-components/products.js'
import {contentfulImagePropTypes, contentfulMarkdownRemarkPropTypes, contentfulMetadataSeoPropTypes} from '../shared-prop-types.js'

const Products = (props) => {
  const {content} = props

  return (
    <Page>
      <Head pageContent={content.metadataSEO}/>
      <Heading1>{content.metadataSEO.title}</Heading1>
      <StyledProductsPageDescription>
        <p>{content.metadataSEO.description}</p>
        <div dangerouslySetInnerHTML={{__html: content.contactCTA.childMarkdownRemark.html}}/>
      </StyledProductsPageDescription>
      <StyledProductsSection>
        <ul>
          {content.products.map((product) => (
            <li key={product.description}>
              <StyledProductFigure backgroundImageURL={product.photo.file.url}>
                <StyledProductFigcaption>{product.description}</StyledProductFigcaption>
              </StyledProductFigure>
            </li>
          ))}
        </ul>
      </StyledProductsSection>
    </Page>
  )
}

Products.propTypes = {
  content: PropTypes.shape({
    contactCTA: PropTypes.shape(contentfulMarkdownRemarkPropTypes).isRequired,
    metadataSEO: PropTypes.shape(contentfulMetadataSeoPropTypes).isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        photo: PropTypes.shape(contentfulImagePropTypes).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
}

const ProductsWithContent = () => (
  <StaticQuery
    // to test queries, refer to the dev server terminal output for the GraphQL URL
    query={graphql`
      query contentfulPageProductsQuery {
        contentfulPageProducts {
          contactCTA {
            childMarkdownRemark {
              html
            }
          }
          metadataSEO {
            canonicalURL
            description
            title
          }
          products {
            description
            photo {
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={data => <Products content={data.contentfulPageProducts}/>}
  />
)

export default ProductsWithContent
