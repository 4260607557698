import {graphql, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {Helmet as ReactHelmet} from 'react-helmet'
import {contentfulImagePropTypes, contentfulMetadataSeoPropTypes} from '../../shared-prop-types.js'

export const Head = (props) => {
  const {pageContent, sitewideContent} = props

  // if it's the homepage, simplify the page title to just the business/website name
  const pageTitle = pageContent.title === 'Home'
    ? sitewideContent.name
    : `${pageContent.title} | ${sitewideContent.name}`

  return (
    <ReactHelmet>
      <link href={pageContent.canonicalURL} rel="canonical"/>
      <meta content={pageContent.description} name="description"/>
      <title>{pageTitle}</title>
      {/* https://css-tricks.com/essential-meta-tags-social-media/ */}
      {/* essential meta tags */}
      <meta content={pageTitle} property="og:title"/>
      <meta content={pageContent.description} property="og:description"/>
      <meta content={`https:${sitewideContent.logo.file.url}`} property="og:image"/>
      <meta content={pageContent.canonicalURL} property="og:url"/>
      <meta content={`https:${sitewideContent.logo.file.url}`} name="twitter:card"/>
      {/* non-essential (but still recommended) meta tags */}
      <meta content={sitewideContent.name} property="og:site_name"/>
      <meta content={`https:${sitewideContent.logo.description}`} name="twitter:image:alt"/>
    </ReactHelmet>
  )
}

Head.propTypes = {
  pageContent: PropTypes.shape(contentfulMetadataSeoPropTypes).isRequired,
  sitewideContent: PropTypes.shape({
    logo: PropTypes.shape(contentfulImagePropTypes).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

const HeadWithContent = (props) => (
  <StaticQuery
    // to test queries, refer to the dev server terminal output for the GraphQL URL
    query={graphql`
      query ContentfulComponentHeadQuery {
        contentfulSitewideMetadataSeo {
          logo {
            description
            file {
              url
            }
          }
          name
        }
      }
    `}
    render={data => (
      <Head
        pageContent={props.pageContent}
        sitewideContent={data.contentfulSitewideMetadataSeo}
      />
    )}
  />
)

export default HeadWithContent
