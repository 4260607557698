import styled from 'styled-components'
import {colors} from '../config/colors.js'

export const StyledProductsPageDescription = styled.div({
  textAlign: 'center',

  // set margin-bottom on direct <p> child only
  '> p': {
    marginBottom: '1.5rem',
  },

  a: {
    color: colors['black-#000'],
    '&:hover': {
      backgroundColor: colors['black-#000'],
      color: colors['white-#ddd'],
    },
  },
})

export const StyledProductsSection = styled.section({
  maxWidth: '100rem',
  margin: '4rem auto',

  ul: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    listStyleType: 'none',
  },

  li: {
    height: '25rem',
    width: '25rem',
  },
})

export const StyledProductFigure = styled.figure(props => ({
    height: '100%',
    width: '100%',
    backgroundImage: `url(https:${props.backgroundImageURL})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}))

export const StyledProductFigcaption = styled.figcaption({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '2rem',
  color: colors['transparent'],
  textAlign: 'center',
  '&:hover': {
    backgroundColor: colors['black-#000-with-shadow'],
    color: colors['white-#ddd'],
  },
})
